.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.login-form {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    line-height: 1.5;
    min-height: 100vh;
    flex-direction: column;
    margin: 0;

    & form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
    }

    & label {
        display: block;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 5px;
        text-align: left;
        color: #555;
        font-weight: bold;
    }

    & .submit-button {
        width: 250px;
        height: 30px;
        text-align: center;
        background-color: #2D9CDB;
    }

    & p {
        padding: 10px;

        & a {
            margin: 10px;
            color: blue;
        }
    }

}


.form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
}

.transact-button, .auth-button, .resolve-button {
    width: 250px;
    height: 50px;
    text-align: center;
    background-color: #2D9CDB;
}
